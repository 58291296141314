<template>
  <div class="messageBoardRoot">
    <div class="message" v-for="(msg, index) in select(reverse(messages), 10)" :key="index">{{msg}}</div>
    <div class="messages">...</div>
  </div>
</template>

<script>
export default {
  name: "MessageBoard",
  props: {
    messages: Array[String]
  },
  methods: {
    reverse(array) {
      return array.slice().reverse();
    },
    select(array, n) {
      const output = []
      for (let i = 0; i < n; ++i) {
        output.push(array[i]);
      }
      return output;
    }
  }
}
</script>

<style scoped>

.messageBoardRoot {
    position: relative;
    width: inherit;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
}

.message {
  margin-top: 2px;
  margin-bottom: 2px;
}

</style>