<template>
  <div class="letter" :class="[style]">
    <input type="text" maxlength="1" class="letterInput" :class="[inputStyle]"
           :value="letter" :disabled="!enabled" @input="$emit('updateLetter', $event.target.value.toUpperCase())"
           :title="nameForType(type)"
           @keydown="filterKey"
           @focus.native="onFocus($event)"/>
    <span class="letterScore">{{letterScore}}</span>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Letter",
  emits: ['updateLetter'],
  props: {
    type: String,
    letter: String,
    enabled: Boolean,
    availableKeys: Array,
    changedLastTurn: Boolean,
    changedThisTurn: Boolean,
  },
  computed: {
    style() {
      return this.type;
    },
    inputStyle() {
      if (this.changedLastTurn) {
        return "softHighlight";
      } else if (this.changedThisTurn || this.type === "highlight") {
        return "highlight";
      } else if (this.type === "grey") {
        return "grey";
      } else if (this.letter) {
        return "white";
      } else {
        return this.type;
      }
    },
    letterScore() {
      const scores = {
        "ENSIRTUAD": 1,
        "HGLO": 2,
        "MBWZ": 3,
        "CFKP": 4,
        "ÄJÜV": 6,
        "ÖX": 8,
        "QY": 10,
        " ": 0,
      }
      if (!this.letter) {
        return "";
      }
      for (const [letters, score] of Object.entries(scores)) {
        if (letters.includes(this.letter)) {
          return score;
        }
      }
      return "";
    }
  },
  methods: {
    filterKey(e) {
      const key = e.key;
      if (key === "Backspace" || key === "Delete") {
        return true;
      }
      if (this.availableKeys.indexOf(key.toUpperCase()) >= 0) {
        return true;
      }
      e.preventDefault();
      return false;
    },
    nameForType(type) {
      const names = {
        "star": "2WS (start)",
        "doubleWord": "2WS",
        "tripleWord": "3WS",
        "doubleLetter": "2LS",
        "tripleLetter": "3LS",
      };
      return type in names ? names[type] : "";
    },
    onFocus(event) {
      event.target.setSelectionRange(0, event.target.value.length);
    }
  }
}
</script>

<style scoped>

  .letterScore {
    font-size: 12px;
    line-height: 14px;
    position: absolute;
    bottom: 3px;
    right: 3px;
    margin: 0;
    color: black;
  }

  .letter {
    position: relative;
    padding: 3px;
  }

  .letterInput {
    font-size: 25px;
    width: calc(1em + 2*4px);
    height: calc(1em + 2*4px);
    padding: 0;
    margin: 0;
    outline: none;
    border: 0;
    border-radius: 0;
    text-align: center;
    box-sizing: border-box;
    text-transform: uppercase;
    -webkit-text-fill-color: black; /* webkit color for disabled text inputs */
    opacity: 1;  /* safari needs this for full color of disabled text input */
  }

  .normal {
    background-color: #98D4BB;
    color: black;
  }

  .white {
    background-color: white;
    color: black;
  }

  .grey {
    background-color: lightgrey;
    color: dimgray;
  }

  .doubleWord {
    background-color: #F9CAD7;
    color: black;
  }
  .doubleLetter {
    background-color: #9AC8EB;
    color: black;
  }
  .tripleWord {
    background-color: #C54B6C;
    color: white;
  }
  .tripleLetter {
    background-color: #5784BA;
    color: white;
  }
  .star {
    background-color: pink;
    color: black;
  }
  .softHighlight {
    background-color: #ffffaa;
    color: black;
  }
  .highlight {
    background-color: yellow;
    color: black;
  }
</style>