<template>
  <div>
    <PlayerList :playing="true" :playerList="state.players" :scores="state.scores" :currentPlayer="currentPlayer"/>
    <input type="button" :disabled="!ownTurn" @click="$emit('endTurn')" value="End turn"/>
  </div>
</template>

<script>
import PlayerList from "./PlayerList";
export default {
name: "SideBar",
  components: {PlayerList},
  emits: ['endTurn'],
  inject: ['state'],
  props: {
    currentPlayer: String,
    ownTurn: Boolean,
  }
}
</script>

<style scoped>

</style>