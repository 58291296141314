<template>
  <div class="board">
    <table>
      <tr v-for="(row, index) in table" :key="index">
        <td v-for="cell in row" :key="cell.column + 100*cell.row">
          <Letter :type="cellType(cell)" :letter="cell.letter"
                  :changedLastTurn="cell.lastTurn" :changedThisTurn="cell.changed" :availableKeys="availableKeys"
                  :enabled="enabled" @updateLetter="updateLetter(cell.row, cell.column, $event)"
                  @dragenter="dragEnter(cell)" @dragover="dragOver(cell, $event)" @drop="handleDrop(cell, $event)"
                  @dragleave="dragLeave()" @dragstart="dragStart(cell, $event)"
                  :draggable="draggable(cell)"
          ></Letter>
        </td>
      </tr>
    </table>
    <transition name="fade">
      <div id="turnIndicator" v-if="displayTurnIndicator"><span>{{ turnIndicatorText }}</span></div>
    </transition>
  </div>
</template>

<script>
import Letter from "./Letter";
import {watch} from "@vue/runtime-core";

function hasChanged(playedThisTurn, i, c) {
  for (const pair of playedThisTurn) {
    const thisPlayed = pair[0] === i && pair[1] === c;
    if (thisPlayed)
      return true;
  }
  return false;
}

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Board",
  emits: ['updateLetter', 'letterDragStarted'],
  inject: ['state'],
  props: {
    enabled: Boolean,
    availableKeys: Array,
    currentPlayer: String,
    ownTurn: Boolean,
  },
  data() {
    return {
      displayTurnIndicator: false,
      currentDragTarget: null,
    }
  },
  components: {
    Letter
  },
  computed: {
    table() {
      let table = []
      for (let i = 1; i <= 15; ++i) {
        table.push([])
        for (let c = 1; c <= 15; ++c) {
          const letter = this.state.board?.length > 0 ? this.state.board[i - 1][c - 1] : "";
          table[i - 1].push({
            column: c,
            row: i,
            type: this.state.boardTypes[i-1][c-1],
            letter: letter,
            lastTurn: hasChanged(this.state.playedLastTurn, i, c),
            changed: hasChanged(this.state.playedThisTurn, i, c),
          })
        }
      }
      return table;
    },
    turnIndicatorText() {
      if (this.state.ended) {
        return "game has ended";
      }
      return (this.ownTurn ? "your turn" : `${this.currentPlayer}'s turn`);
    }
  },
  methods: {
    draggable(cell) {
      return hasChanged(this.state.playedThisTurn, cell.row, cell.column);
    },
    cellType(cell) {
      if (this.currentDragTarget === null) {
        return cell.type;
      } else if (this.currentDragTarget.row === cell.row
                 && this.currentDragTarget.column === cell.column) {
        return "highlight";
      }  else {
        return cell.type;
      }
    },
    canDrop(cell) {
      return cell.letter === "" || hasChanged(this.state.playedThisTurn, cell.row, cell.column);
    },
    dragStart(cell, event) {
      if (hasChanged(this.state.playedThisTurn, cell.row, cell.column)) {
        // we edited this cell this turn, allow to drag the letter back to the hand
        event.dataTransfer.effectAllowed = "move";
        event.dataTransfer.setData("text/plain", cell.letter);
        // Safari on iOS needs html data for drag to work at all
        event.dataTransfer.setData("text/html", cell.letter);
        this.$emit("letterDragStarted", cell);
      }
    },
    dragEnter(cell) {
      if (this.canDrop(cell))
        this.currentDragTarget = {row: cell.row, column: cell.column};
    },
    dragLeave() {
      this.currentDragTarget = null;
    },
    dragOver(cell, event) {
      if (this.canDrop(cell)) {
        event.preventDefault();
        this.currentDragTarget = {row: cell.row, column: cell.column};
      }
    },
    handleDrop(cell, event) {
      if (this.canDrop(cell)) {
        event.stopPropagation();
        event.preventDefault();
        const letter = event.dataTransfer.getData("text/plain");
        this.updateLetter(cell.row, cell.column, letter);
        this.currentDragTarget = null;
        return false;
      }
      return true;
    },
    updateLetter(row, column, letter) {
      const event = {row, column, letter};
      this.$emit('updateLetter', event);
    }
  },
  created() {
    const vm = this;

    function displayTurnIndicator() {
      vm.displayTurnIndicator = true;
      setTimeout(function() {
        vm.displayTurnIndicator = false;
      }, 2000);
    }

    watch(
        () => this.currentPlayer,
        displayTurnIndicator,
    );

    displayTurnIndicator(); // display when starting the game
  }
}
</script>

<style scoped>
td {
  padding: 0;
}

table, td {
  border-style: solid;
  border-collapse: collapse;
  border-spacing: 0;
  border-width: 2px;
  border-color: black;
}

.board {
  display: block;
}

#turnIndicator {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 250px;
  height: 50px;
  margin: auto;
  background-color: white;
  padding: 20px;
}

#turnIndicator span {
  font-size: 25px;
  max-height: 50px;
  max-width: 250px;
  position: absolute;
  top: calc(50px/2 + 20px);
  transform: translateY(-50%);
  left: 20px;
  right: 20px;
  text-overflow: ellipsis;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .75s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

</style>