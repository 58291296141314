<template>
  <!-- we allow drops on the highest level of this component to make sure we catch the drop event -->
  <div  @dragover="dragOver($event)" @drop="handleDrop($event)">
    <transition-group name="hand-letter-list" tag="span">
      <span v-for="(letter, index) in reordered"  :key="display_order[index]" class="letterContainer"
            draggable="true" @dragstart="dragStart(index, $event)" @dragenter="dragEnter(index, $event)"
            @dragend="handleDrop($event)">
        <Letter v-if="!letterPlayed[display_order[index]]" :class="letterClass" :letter="letter" :enabled="false"
                :type="specificType(display_order[index])" @click="toggleSelection(display_order[index])" />
        <Letter v-if="letterPlayed[display_order[index]]"  :class="letterClass" :letter="letter" :enabled="false" type="grey"/>
      </span>
    </transition-group>
    <br/>
    <button id="swapButton" v-if="selected_lst.length > 0" @click="swapEndTurn()">Swap selected and end turn</button>
  </div>
</template>

<script>
import Letter from "./Letter";
import {watch} from "@vue/runtime-core";
import _ from "lodash";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Hand",
  emits: ['swapAndEndTurn', 'handOrderChanged'],
  inject: ['state'],
  props: {
    ownTurn: Boolean,
    enabled: Boolean,
    boardDragLetter: String,
  },
  data() {
    return {
      selected_lst: [],
      display_order: [0, 1, 2, 3, 4, 5, 6],
      saved_order: [],
      dragSrcPosition: null,
      transitioning: [],
    }
  },
  components: {
    Letter
  },
  computed: {
    type() {
      if (this.ownTurn) {
        return "white";
      } else {
        return "grey";
      }
    },
    letterClass() {
      return {
        handLetter: true,
        white: this.ownTurn,
        grey: !this.ownTurn
      }
    },
    letterPlayed() {
      const played = [];
      for (let i = 0; i < this.state.hand.length; ++i) {
        played.push(false);
      }
      for (const index of this.state.currentPlayed) {
        played[index] = true;
      }
      return played;
    },
    selected() {
      const selected = Array(this.state.hand.length).fill(false);
      for (const i of this.selected_lst) {
        selected[i] = true;
      }
      return selected;
    },
    reordered() {
      const reordered = [];
      for (let i = 0; i < this.display_order.length; ++i) {
        reordered.push(this.state.hand[this.display_order[i]]);
      }
      return reordered;
    },
    maySwap() {
      return this.state.currentPlayed.length === 0;
    }
  },
  methods: {
    toggleSelection(id) {
      console.log("clicked", id);
      if (!this.maySwap || !this.enabled) {
        return;
      }
      const index = this.selected_lst.indexOf(id);
      if (index >= 0) {
        this.selected_lst.splice(index, 1);
      } else {
        this.selected_lst.push(id);
      }
    },
    clearSelection() {
      this.selected_lst = [];
    },
    specificType(index) {
      if (this.selected[index]) {
        return "highlight";
      } else {
        return this.type;
      }
    },
    dragStart(index, event) {
      event.dataTransfer.effectAllowed = "move";
      const letter = this.state.hand[this.display_order[index]];
      // the board uses this to place the letter
      event.dataTransfer.setData("text/plain", letter);
      // Safari on iOS needs html data for drag to work at all
      event.dataTransfer.setData("text/html", letter);
      this.dragSrcPosition = index;
      this.saved_order = this.display_order.slice();
    },
    // eslint-disable-next-line no-unused-vars
    dragEnter(dragTargetPosition, event) {
      if (this.dragSrcPosition === null) {
        if (this.saved_order.length === 0) {
          this.saved_order = this.display_order.slice();
        }
        // accepting drag from hand, so let's set a matching dragSrcPosition
        for (let index of this.state.currentPlayed) {
          console.log(index, this.state.currentPlayed);
          if (this.state.hand[index] === this.boardDragLetter) {
            this.dragSrcPosition = this.saved_order.indexOf(index);
            if (this.dragSrcPosition < 0) {
              this.dragSrcPosition = null;
            }
            break;
          }
        }
      }

      if (this.dragSrcPosition == null)
        return;
      if (this.dragSrcPosition === dragTargetPosition)
        return;
      if (this.saved_order[this.dragSrcPosition] === this.display_order[dragTargetPosition])
        return; // we have already swapped, but the animation might mess with us
      if (this.transitioning.indexOf(dragTargetPosition) >= 0)
        return; // target is currently moving (because of a previous drag move) so we likely don't want to move here

      const new_display_order = [];
      const transitioning = [];
      let tracking_transitioning = false;
      let offset = 0;
      for (let i = 0; i < this.display_order.length; ++i) {
        if (i === dragTargetPosition) {
          tracking_transitioning = !tracking_transitioning;
          offset -= 1
          new_display_order.push(this.saved_order[this.dragSrcPosition]);
        } else {
          if (this.dragSrcPosition > dragTargetPosition) {
            new_display_order.push(this.saved_order[i + offset]);
          }
          if (i === this.dragSrcPosition) {
            tracking_transitioning = !tracking_transitioning;
            offset += 1
          }
          if (this.dragSrcPosition < dragTargetPosition) {
            new_display_order.push(this.saved_order[i + offset]);
          }
        }
        if (tracking_transitioning) {
          transitioning.push(new_display_order[new_display_order.length-1])
        }
      }
      let changed = false;
      for (let i = 0; i < new_display_order.length; ++i) {
        if (this.display_order[i] !== new_display_order[i]) {
          changed = true;
          break;
        }
      }

      if (changed) {
        this.transitioning = transitioning;
        this.display_order = new_display_order;

        this.clearDebounced(this);
      }
    },
    clearDebounced: _.debounce(function (vm) {
        vm.transitioning = [];
      }, 750
    ), // sync with transition time
    dragOver(event) {
      event.preventDefault();
      return false;
    },
    handleDrop(event) {
      if (this.dragSrcPosition === null)
        return; // we have two potential handlers handling this
        // drags from the board get a dragSrcPosition assigned on enter
      event.stopPropagation();
      event.preventDefault();

      this.$emit("handOrderChanged", this.display_order);
      this.dragSrcPosition = null;

      return false;
    },
    swapEndTurn() {
      this.$emit('swapAndEndTurn', this.selected_lst);
      this.selected_lst = [];
    }
  },
  created() {
    const vm = this;
    watch(
        () => vm.ownTurn,
        () => vm.selected_lst = []
    )

    function updateHandOrder() {
      vm.display_order = vm.state.handOrder;
    }
    watch(
        () => vm.state.handOrder,
        updateHandOrder
    );
    updateHandOrder();
  },
}
</script>

<style scoped>

.letterContainer {
  display: inline-block;
  line-height: calc(34px + 10px);
}

.handLetter {
  display: inline-block;
  width: 34px;
  height: 34px;
  border: 1px solid;
}

.white {
  border: 1px black solid;
}

.grey {
  border: 1px grey solid;
}

#swapButton {
  position: relative;
  display: block;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: inherit;
}

.hand-letter-list-move {
  transition: all 0.75s; /* sync with transition detection */
}

</style>