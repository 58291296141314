<template>
  <div>
    <label for="playerNameInput">Enter your name: </label>
    <input id="playerNameInput" type="text" v-model="modelValue" />
    <button @click="$emit('changeName', modelValue)">Change Name</button>
  </div>
</template>

<script>
import {watch} from "@vue/runtime-core";

export default {
  name: "PlayerName",
  props: {
    name: String,
  },
  data() {
    return {
      modelValue: ""
    }
  },
  methods: {
    setName() {
      this.modelValue = this.name;
    }
  },
  created() {
    const vm = this;
    watch(
        () => vm.name,
        () => {
          vm.setName();
        }
    );
    vm.setName();
  }
}
</script>

<style scoped>

</style>