
class State {

  constructor() {
    this.board = [];
    this.boardTypes = [];
    this.currentPlayed = [];
    this.currentPlayer = -1;
    this.ended = false;
    this.hand = [];
    this.handOrder = [];
    this.locked = true;
    this.messages = [];
    this.options = { stonesOnHand: 7, };
    this.playedLastTurn = [];
    this.playedThisTurn = [];
    this.players = [];
    this.playersWantGameEnd = []
    this.running = false;
    this.scores = [];
    this.new_game = null;

    // client-only
    this.boardCellBeingDragged = null;
    this.gameId = "";
    this.gameUrl = window.location.href;
    this.playerId = -1;

    for (let i = 0; i < 15; ++i) {
      this.board.push([])
      for (let j =0; j < 15; ++j) {
        this.board[i].push("");
      }
    }
  }
}

class MetaState {
  constructor() {
    this.playersOnline = []
  }
}

function updateState(state, c) {
  for (let prop of Object.keys(c)) {
    if (!(prop in state)) {
      console.error("Received invalid property from server:", prop);
      continue;
    }
    state[prop] = c[prop];
  }
}

export {
  State,
  MetaState,
  updateState
};