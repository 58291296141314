<template>
  <div>
    <div id="connectionDroppedBox" v-if="!wsConnected">
      Connection to game server was disconnected. Please reload the website.
    </div>
    <div v-if="!state.running">
      <h1>Our Words: {{state.gameId}}</h1>
      <UnlockGame v-if="state.locked" @unlock="unlockGame($event)"></UnlockGame>
      <div v-if="!state.locked">
        <PlayerName id="playerName" :name="ownName" @changeName="changeName($event)"/>
        <label for="inputStonesOnHand">Stones on hand: </label>
        <input id="inputStonesOnHand" type="number" min="7" max="10" step="1" v-model="state.options.stonesOnHand"/>
        <div id="inviteOthers">Invite others: <a :href="state.gameUrl">{{ state.gameUrl }}</a></div>
        <PlayerList :player-list="state.players" :current-player="ownName"/>
        <input id="startGame" :disabled="state.players.length === 0 || state.locked" type="button" @click="startGame()" value="Start Game!"/>
        <div id="gameVersion">Version: {{gameVersion}}</div>
      </div>
    </div>
    <div v-if="state.running" id="gameContainer">
      <Board id="board" ref="board" :availableKeys="availableKeys"
             :enabled="ownTurn && !state.ended" :ownTurn="ownTurn" :currentPlayer="currentPlayerName"
             @update-letter="updateLetter($event)" @letterDragStarted="dragFromBoard($event)"
      />
      <div id="rightContainer">
        <SideBar :currentPlayer="currentPlayerName" :ownTurn="ownTurn" @endTurn="endTurn()"/>
        <Hand id="handContainer" ref="hand" :enabled="ownTurn" :ownTurn="ownTurn" @swapAndEndTurn="swapAndEndTurn" @handOrderChanged="sendHandOrder"
              :boardDragLetter="state.boardCellBeingDragged?.letter" />
      </div>
      <div class="infoBoxBelowBoard">
        <div v-if="state.ended" id="gameEndedContainer">
          <span>Game has ended.</span><br/>
          <button v-if="state.new_game === null" @click="newGame()">New Game</button>
          <button v-if="state.new_game !== null" @click="joinNewGame()">Join new game: {{state.new_game}}</button>
        </div>

        <MessageBoard :messages="state.messages"></MessageBoard>
      </div>
    </div>
  </div>
</template>

<script>
import Board from "./Board";
import PlayerName from "./PlayerName";
import PlayerList from "./PlayerList";
import SideBar from "./SideBar";
import Hand from "./Hand";
import MessageBoard from "./MessageBoard";
import UnlockGame from "./UnlockGame";
import {watch} from "@vue/runtime-core";
import _ from "lodash";
import {MetaState, State, updateState} from "@/state";
import {computed} from "vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Game",
  components: {
    PlayerList,
    Board, PlayerName,
    SideBar, Hand, MessageBoard, UnlockGame
  },
  data() {
    return {
      state: new State(),
      metaState: new MetaState(),
      gameVersion: "unknown",
      wsConnected: true,
    }
  },
  provide() {
    return {
      state: computed(() => this.state), // reactify
      metaState: computed(() => this.metaState), // reactify
    }
  },
  computed: {
    currentPlayerName() {
      return this.state.players[this.state.currentPlayer];
    },
    ownName() {
      return this.state.players[this.state.playerId];
    },
    ownTurn() {
      return this.state.playerId === this.state.currentPlayer;
    },
    availableKeys() {
      const played = Array(this.state.hand.length);
      for (const i of this.state.currentPlayed) {
        played[i] = true;
      }
      return this.state.hand.filter((v, index) => !played[index]);
    },
  },
  methods: {
    registerPlayer(name) {
      console.log("registering", name)
      this.ws.send(JSON.stringify({
        "type": "REGISTER",
        "name": name,
      }))
    },
    startGame() {
      console.log("starting game")
      this.ws.send(JSON.stringify({
        "type": "START_GAME",
      }))
    },
    updateLetter(event) {
      // it is not allowed to swap letters once you have placed letters
      this.$refs.hand.clearSelection();

      if (this.state.boardCellBeingDragged) {
        const dragged = this.state.boardCellBeingDragged;
        this.state.boardCellBeingDragged = null;
        this.updateLetter({
          "row": dragged.row,
          "column": dragged.column,
          "letter": "",
        })
      }

      const letter = event.letter;
      const index = this.state.hand.indexOf(letter);
      console.log(letter, index, this.state.currentPlayed)
      if (letter === "" || (!this.state.currentPlayed
          .indexOf(index) >= 0 && index >= 0)) {
        this.state.board[event.row-1][event.column-1] = letter;
        this.ws.send(JSON.stringify({
          "type": "PLACE_LETTER",
          "row": event.row,
          "column": event.column,
          "letter": event.letter,
        }));
      } else {
        // clear out invalid user input
        this.state.board[event.row-1][event.column-1] = "";
      }
    },
    endTurn() {
      this.ws.send(JSON.stringify({
        "type": "END_TURN",
      }))
    },
    swapAndEndTurn(selected) {
      this.sendHandOrder(this.$refs.hand.display_order);
      this.ws.send(JSON.stringify({
        "type": "SWAP_AND_END_TURN",
        "selected": selected,
      }));
    },
    newGame() {
      this.ws.send(JSON.stringify({
        "type": "INVITE_NEW_GAME",
      }))
    },
    joinNewGame() {
      window.location.pathname = `/game/${this.state.new_game}`;
    },
    unlockGame(password) {
      this.ws.send(JSON.stringify({
        "type": "UNLOCK",
        "password": password,
      }));
    },
    changeName(name) {
      this.registerPlayer(name);
    },
    sendHandOrder(order) {
      if (this.state.boardCellBeingDragged) {
        this.updateLetter({
          row: this.state.boardCellBeingDragged.row,
          column: this.state.boardCellBeingDragged.column,
          letter: ""
        });
        this.state.boardCellBeingDragged = null;
      }
      this.ws.send(JSON.stringify({
        "type": "HAND_ORDER",
        "handOrder": order,
      }));
    },
    dragFromBoard(cell) {
      this.state.boardCellBeingDragged = cell;
    },
    sendOptions() {
      this.ws.send(JSON.stringify({
        "type": "OPTIONS",
        "options": this.state.options,
      }));
    },
  },
  created() {
    const vm = this;

    vm.state.gameId = window.location.pathname.split("/")[2];
    const protocol = window.location.protocol === "https:" ? "wss" : "ws";
    let port = process.env.VUE_APP_SERVER_PORT ? process.env.VUE_APP_SERVER_PORT:
                          window.location.port ? window.location.port : "";
    port = port ? `:${port}` : "";
    this.ws = new WebSocket(`${protocol}://${window.location.hostname}${port}/games/${this.state.gameId}/ws`);

    this.ws.onmessage = function(event) {
      const d = JSON.parse(event.data);
      vm.state.playerId = d.playerId;
      const c = d.changes;
      console.log("ws received", d)
      switch (d.type) {
        case "STATE_CHANGES":
          updateState(vm.state, c);
          break;
        case "META_STATE_CHANGES":
          updateState(vm.metaState, c);
          break;
        default:
          console.error("unknown incoming message type", d.type, d);
          break;
      }
    };

    this.ws.onclose = function() {
      vm.wsConnected = false;
    };

    watch(
        () => vm.state.ended,
        function() {
          if (vm.state.ended) {
            vm.state.currentPlayer = undefined;
          }
    });

    watch(
        () => _.cloneDeep(vm.state.options),
        function(new_value, old_value) {
          if (!_.isEqual(new_value, old_value))
            vm.sendOptions();
        },
        {deep: true},
    );

    const versionQuery = new XMLHttpRequest();
    versionQuery.onload = () => {
      vm.gameVersion = versionQuery.responseText;
    };
    versionQuery.open("GET", `${window.location.protocol}//${window.location.hostname}${port}/version`);
    versionQuery.send();
  }
}
</script>

<style scoped>

#gameContainer {

}

#board {
  display: table-cell;
  position: relative;
}

#rightContainer {
  display: table-cell;
  width: 240px;
  height: inherit;
}

#handContainer {
  width: 200px;
  position: relative;
  right: 0;
  margin-top: 130px;
  align-content: center;
  display: inline-block;
}

#gameEndedContainer {
  position: relative;
  width: inherit;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}

#gameEndedContainer button {
  margin-top: 10px;
}

#inviteOthers {
  margin-top: 10px;
}

#inviteOthers a {
  color: blue;
}

#turnIndicator {
  position: static;
  background-color: white;
}

#playerName {
  margin-bottom: 10px;
}

#gameVersion {
  position: fixed;
  width: 100%;
  bottom: 0;
}

.infoBoxBelowBoard {
  width: 615px;
}

#connectionDroppedBox {
  position: absolute;
  width: 25%;
  background: white;
  z-index: 1000;
  left: 35.5%;
  top: 35.5%;
  font-size: 25px;
  padding: 20px;
  border: 1px solid darkred;
  box-shadow: 0 0 30px darkred;
}

</style>