<template>
  <div>
    <label for="passwordInput">Enter password to create game: </label>
    <input id="passwordInput" type="text" v-model="password"/>
    <button @click="$emit('unlock', password)">Create game!</button>
  </div>
</template>

<script>
export default {
  name: "UnlockGame",
  emits: ['unlock'],
  data() {
    return {
      password: "",
    }
  }
}
</script>

<style scoped>

</style>