<template>
  <div>
    <h1>Players</h1>
    <div id="listContainer">
      <ul id="playerList">
        <li v-for="(player, index) in playerList" :key="player"
            :class="{currentPlayerListItem: player === currentPlayer,
                     wantsGameEnd: state.playersWantGameEnd[index],
                     playerOffline: !metaState.playersOnline[index],
                    }">
          {{player}} {{get_score(index)}}
        </li>
      </ul>
      <div v-if="playing" id="currentPlayerHighlight"></div>
    </div>
  </div>

</template>

<script>
import {watch} from "@vue/runtime-core";

export default {
  name: "PlayerList",
  inject: ["state", "metaState"],
  props: {
    playerList: Array[String],
    scores: Array[Number],
    currentPlayer: String,
  },
  computed: {
    currentPlayerIndex() {
      return this.playerList.indexOf(this.currentPlayer);
    },
    playing() {
      return this.state.running && !this.state.ended;
    }
  },
  methods: {
    get_score(index) {
      if (this.scores) {
        return `(${this.scores[index]})`;
      } else {
        return "";
      }
    }
  },
  mounted() {
    const vm = this;

    function setupHighlightPosition() {
      if (vm.state.ended) {
        return;
      }
      const highlight = document.getElementById("currentPlayerHighlight");
      const playerList = document.getElementById("playerList");
      if (!highlight || !playerList)
        return;
      const to_highlight = playerList.children[vm.currentPlayerIndex];
      const parent_top = playerList.getBoundingClientRect().top;
      const top_margin = 5;
      const top = to_highlight.getBoundingClientRect().top - parent_top - top_margin;
      if (highlight && to_highlight)
        highlight.style.top = `${top}px`;
    }

    watch(
      () => [vm.currentPlayerIndex, vm.playerList],
      setupHighlightPosition
    )
    setupHighlightPosition();
  }
}
</script>

<style scoped>
.currentPlayerListItem {
  font-weight: bold;
}

.wantsGameEnd {
  text-decoration: line-through;
}

.playerOffline {
  color: darkgrey;
}

h1 {
  text-align: center;
}

ul {
  list-style-type: none;
  text-align: center;
  padding-left: 0;
}

li {
  margin: 5px;
  font-size: 24px;
}

#listContainer {
  display: block;
  position: relative;
}

#currentPlayerHighlight {
  display: block;
  position: absolute;
  z-index: -1;
  left: 10px; right: 10px;
  background-color: yellow;
  transition-property: top;
  transition-duration: 0.75s;

  height: calc(24px + 4px);
  padding: 5px;
}

</style>